import "core-js/modules/es.array.for-each";import "core-js/modules/web.dom-collections.for-each"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: function data() {
    return {
      code: '',
      orderData: {},
      ossUrl: '',
      projectData: [],
      tableData: [] };


  },
  methods: {
    getProjectData: function getProjectData(projectData, sampleCode, takeTime) {
      var data = projectData;
      data.forEach(function (item) {
        item.sampleCode = sampleCode;
        item.takeTime = takeTime;
      });
      this.projectData = data;
    } },

  mounted: function mounted() {
    this.orderData = JSON.parse(this.$route.query.data);
    this.ossUrl = process.env.VUE_APP_API_OSS_CONFIG_PRO_URL;
    this.code = "data:image/png;base64," + this.orderData.barCode;
    this.getProjectData(this.orderData.order.projectVos, this.orderData.order.sampleCode, this.orderData.order.logisticsVo.takeTime);
  } };