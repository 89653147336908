var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "top-header" }, [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("el-image", {
            staticStyle: { width: "100px", height: "70px" },
            attrs: {
              src: _vm.ossUrl + _vm.orderData.order.paymentVo.payeeIcon,
              fit: "fill"
            }
          }),
          _c("div", { staticClass: "top-title" }, [
            _vm._v(_vm._s(_vm.orderData.order.paymentVo.payeeName))
          ])
        ],
        1
      ),
      _c("hr", { staticClass: "line" }),
      _c("div", { staticClass: "bottom" }, [
        _c("div", { staticClass: "bottom-title" }, [
          _vm._v(
            _vm._s(_vm.orderData.order.paymentVo.payeeName) + "检测项目申请单"
          )
        ]),
        _c(
          "div",
          { staticClass: "image" },
          [
            _c("el-image", {
              staticStyle: { width: "300px", height: "60px" },
              attrs: { src: _vm.code, fit: "fill" }
            })
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "box" },
      [
        _c("div", { staticClass: "box-header" }, [_vm._v("患者信息")]),
        _c("div", { staticClass: "box-item" }, [
          _c("div", [
            _vm._v("姓名:" + _vm._s(_vm.orderData.order.patientVo.name))
          ]),
          _c("div", [
            _vm._v(
              "性别:" +
                _vm._s(_vm.orderData.order.patientVo.gender === 1 ? "男" : "女")
            )
          ]),
          _c("div", [
            _vm._v("年龄:" + _vm._s(_vm.orderData.order.patientVo.name))
          ]),
          _c("div", [
            _vm._v(
              "患者联系号码:" + _vm._s(_vm.orderData.order.patientVo.mobile)
            )
          ]),
          _c("div")
        ]),
        _c(
          "div",
          { staticClass: "box-item", staticStyle: { border: "none" } },
          [
            _c("div", [
              _vm._v(
                "送检医院:" + _vm._s(_vm.orderData.order.doctorVo.hospital)
              )
            ]),
            _c("div", [
              _vm._v("科室:" + _vm._s(_vm.orderData.order.doctorVo.deptName))
            ]),
            _c("div", [
              _vm._v("床位号:" + _vm._s(_vm.orderData.order.patientVo.bedNum))
            ]),
            _c("div", [
              _vm._v("送检医师:" + _vm._s(_vm.orderData.order.doctorVo.name))
            ]),
            _c("div", [
              _vm._v(
                "医师联系号码:" + _vm._s(_vm.orderData.order.doctorVo.comment)
              )
            ])
          ]
        ),
        _c("div", { staticClass: "box-item" }, [
          _vm._v("医师备注信息:" + _vm._s(_vm.orderData.order.doctorVo.mobile))
        ]),
        _c("div", { staticClass: "box-header" }, [_vm._v("送检项目")]),
        _c(
          "el-table",
          {
            ref: "singleTable",
            staticStyle: { width: "100%" },
            attrs: { data: _vm.projectData, border: "" }
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", label: "序号", width: "80" }
            }),
            _c("el-table-column", {
              attrs: { property: "name", label: "检测名称", width: "150" }
            }),
            _c("el-table-column", {
              attrs: { property: "code", label: "检测编号", width: "150" }
            }),
            _c("el-table-column", {
              attrs: { property: "sampleCode", label: "血样编号" }
            }),
            _c("el-table-column", {
              attrs: { property: "takeTime", label: "标本采集时间" }
            })
          ],
          1
        ),
        _c("div", { staticClass: "box-header" }, [_vm._v("附加信息")]),
        _c("div", { staticClass: "box-addOtherMessage" }),
        _c("div", { staticClass: "box-header" }, [_vm._v("标本信息")]),
        _c("div", { staticClass: "box-addOtherMessage" }),
        _c("div", { staticClass: "box-header" }, [_vm._v("责任人")]),
        _c("div", { staticClass: "box-people" }, [
          _c("div", [
            _vm._v(
              "责任销售：" +
                _vm._s(_vm.orderData.order.saleVo.name) +
                " 联系电话：" +
                _vm._s(_vm.orderData.order.saleVo.mobile)
            )
          ]),
          _c("div", [
            _vm._v(
              "责任物流：" +
                _vm._s(_vm.orderData.order.logisticsVo.name) +
                " 联系电话：" +
                _vm._s(_vm.orderData.order.logisticsVo.mobile)
            )
          ])
        ])
      ],
      1
    ),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "flex-end",
          width: "1000px",
          height: "100px"
        }
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              width: "50%",
              display: "flex",
              "flex-direction": "column",
              "justify-content": "center",
              "align-items": "center"
            }
          },
          [
            _c("div", { staticStyle: { "font-size": "18px" } }, [
              _vm._v("医检助手电子申请单")
            ]),
            _c("div", { staticStyle: { "font-size": "18px" } }, [
              _vm._v("本图片代替纸质版申请单在 lis 系统备案")
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }